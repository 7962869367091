import * as React from 'react'
import { Link } from 'gatsby'
import { Navigation } from '../common/navigation'
import { GlobalHelmet } from '../common/seo/globalHelmet'
import { CMSWrapper } from '../components/cmsWrapper'
import { Footer } from '../common/footer'
import { StationsHeaderCare } from '../components/search/stationsHeaderCare'
import { StationsHeaderMfa } from '../components/search/stationsHeaderMfa'
import { StationsHeaderMtra } from '../components/search/stationsHeaderMtra'
import { StationsHeaderMtla } from '../components/search/stationsHeaderMtla'
import { StaticVideo } from '../components/staticSections'
import { AREA_CARE, AREA_MTRA, AREA_MTLA, AREA_MFA } from '../config/constants'
import {
  filterClustersOfStations,
  filterStationsSuitableForGeriatricNurses,
  filterStationsByArea,
} from '../helpers/filters'
import fetch from 'node-fetch'

export default function Stations({ pageContext }) {
  const [searchInput, setSearchInput] = React.useState()
  const [stations, setStations] = React.useState([])
  const [clusters, setClusters] = React.useState([])

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      fetch('/_cms/stations').then((response) => {
        response.json().then((data) => {
          const filteredStationsByArea = filterStationsByArea(
            data,
            pageContext.area
          )
          const stationsSuitableForGeriatricNurses = filterStationsSuitableForGeriatricNurses(
            data
          )
          fetch('/_cms/clusters').then((response) => {
            response.json().then((data) => {
              const stations = pageContext.suitableForGeriatricNurses
                ? stationsSuitableForGeriatricNurses
                : filteredStationsByArea
              const filteredClustersByArea = filterClustersOfStations(
                data,
                stations
              )
              const clustersSuitableForGeriatricNurses = filterClustersOfStations(
                data,
                stations
              )
              const clusters = pageContext.suitableForGeriatricNurses
                ? clustersSuitableForGeriatricNurses
                : filteredClustersByArea

              setClusters(
                clusters.map((cluster) => ({
                  ...cluster,
                  slug: '/' + cluster.path,
                }))
              )
              setStations(stations)
            })
          })
        })
      })
    }

    if (process.env.NODE_ENV !== 'development') {
      setStations(pageContext.stations)
      setClusters(pageContext.clusters)
    }
  }, [
    pageContext.area,
    pageContext.stations,
    pageContext.clusters,
    pageContext.suitableForGeriatricNurses,
  ])

  const clusterView = React.useMemo(() => {
    return (
      <div id="clusterTemplate">
        {getSortedClusters(clusters).map((cluster, clusterIndex) =>
          stations.filter((station) => hasCluster(station, cluster)).length >
            0 || cluster.landingpageTitle ? (
            <span key={clusterIndex}>
              <h2>
                <button
                  className="text-start"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${clusterIndex}`}
                  aria-expanded="false"
                  aria-controls={`collapse${clusterIndex}`}
                >
                  {cluster.title}
                </button>
              </h2>
              <ul
                id={`collapse${clusterIndex}`}
                className="collapse link-list"
                data-bs-parent="#accordion-example"
              >
                {cluster.landingpageShowInExplorer &&
                  cluster.landingpageTitle ? (
                  <li id="clusterLandingPage">
                    <h4>
                      <Link className="bg-yellow" to={cluster.slug}>
                        {cluster.landingpageTitle}
                      </Link>
                      {cluster.landigpageQuote ? (
                        <small>{cluster.landigpageQuote}</small>
                      ) : null}
                    </h4>
                  </li>
                ) : null}
                {stations
                  .filter((station) => hasCluster(station, cluster))
                  .map((station, stationIndex) => (
                    <li id="clusterStationTemplate" key={stationIndex}>
                      <h4>
                        <Link
                          className="bg-dark-blue"
                          to={station.slug}
                          state={{
                            carouselTitle: cluster.title,
                            carouselSlugs: stations
                              .filter((station) => hasCluster(station, cluster))
                              .map((station) => station.slug),
                          }}
                        >
                          {station.header.title}
                        </Link>
                        <small>{station.header.introText}</small>
                      </h4>
                    </li>
                  ))}
              </ul>
            </span>
          ) : null
        )}

        {stations
          .filter((station) => hasAnyCluster(station) === false)
          .map((station, stationIndex) => (
            <h4 key={stationIndex}>
              <Link className="bg-dark-blue" to={station.slug}>
                {station.header.title}
              </Link>
              <small>{station.header.introText}</small>
            </h4>
          ))}
      </div>
    )
  }, [clusters, stations])

  const searchResult = React.useMemo(() => {
    const filteredStations = stations.filter((station) => {
      let tagsLowerCase = []
      if (station.metainfo && station.metainfo.tags) {
        tagsLowerCase = station.metainfo.tags.map((value) =>
          value.toLowerCase()
        )
      }
      let matched = false
      if (
        station.header.title.toLowerCase().includes(searchInput) ||
        tagsLowerCase.includes(searchInput)
      ) {
        matched = true
      } else {
        for (let i = 0; i < tagsLowerCase.length; i++) {
          if (tagsLowerCase[i].includes(searchInput)) {
            matched = true
            break
          }
        }
      }
      return matched
    })

    return (
      <ul className="link-list">
        {filteredStations.map((station) => (
          <li id="searchStationTemplate" data-tags="" key={station.slug}>
            <h4 className="mb-0">
              <Link className="bg-dark-blue" to={station.slug}>
                {station.header.title}
              </Link>
              <small>{station.header.introText}</small>
            </h4>
          </li>
        ))}
      </ul>
    )
  }, [searchInput, stations])

  return (
    <CMSWrapper>
      <GlobalHelmet title="UKE jukebox - Stationsübersicht" />
      <main>
        <Navigation />
        {pageContext.area === AREA_CARE && (
          <>
            <StationsHeaderCare />

            <section className="pb-0">
              <div className="row">
                <div className="col-md-10 offset-md-1 col-12">
                  <StaticVideo
                    video={{
                      url: 'https://player.vimeo.com/video/923663750?h=e83a07c5a3',
                    }}
                  />
                </div>
              </div>
            </section>
          </>
        )}
        {pageContext.area === AREA_MFA && <StationsHeaderMfa />}
        {pageContext.area === AREA_MTLA && <StationsHeaderMtla />}
        {pageContext.area === AREA_MTRA && <StationsHeaderMtra />}

        <section className="mt-0 pb-md-0 pb-4">
          <div className="row align-items-center">
            <input
              className="col-md-5 offset-md-2 col-12"
              type="search"
              name="stationssuche"
              placeholder="Suchen..."
              onChange={(e) => setSearchInput(e.target.value.toLowerCase())}
            />
            <a
              href="#stations"
              className="col-md-2 offset-md-1 button bg-blue col-6 offset-3 text-center"
            >
              Finden
            </a>
          </div>
        </section>
        <section id="stations" className="bg-blue divider-center">
          <div className="row">
            <div
              id="accordion-example"
              className="col-md-8 offset-md-2 col-12 accordion"
            >
              {searchInput && searchInput.length > 0 ? searchResult : null}
              {!searchInput || searchInput.length < 1 ? clusterView : null}
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </CMSWrapper>
  )
}

const hasCluster = (station, cluster) => {
  return (
    station.metainfo &&
    station.metainfo.clusters &&
    station.metainfo.clusters.includes(cluster.key)
  )
}

const hasAnyCluster = (station) => {
  return (
    station.metainfo &&
    station.metainfo.clusters &&
    station.metainfo.clusters.length > 0
  )
}

const getSortedClusters = (clusters) => {
  return [...clusters].sort((a, b) => a.title.localeCompare(b.title, 'de'))
}
