import * as React from 'react'

export function StationsHeaderMtla() {
  return (
    <header className="bg-img-yellow divider-left mb-0">
      <div className="row align-items-center">
        <div className="col-md-5 offset-md-1 col-10 offset-1 text-center">
          <img
            src="/img/handlettering/handlettering-bei-uns-stimmt-die-chemie.svg"
            alt="Handlettering: Bei uns stimmt die Chemie"
            title="Bei uns stimmt die Chemie"
            width="370"
          />
        </div>
        <div className="col-md-5 col-12 text-center">
          <h1>Unsere Bereiche auf einen Blick</h1>
          <h3>
            Hier findest du all unsere <strong>MTLA</strong>-Bereiche im
            Überblick, sortiert nach Fachbereichen.
            <br />
            Du weißt, was du dir anschauen möchtest? Dann nutze einfach unsere
            Suche.
          </h3>
        </div>
      </div>
    </header>
  )
}
