import * as React from 'react'

export function StationsHeaderMtra() {
  return (
    <header className="bg-img-yellow divider-left mb-0">
      <div className="row align-items-center">
        <div className="col-md-5 offset-md-1 col-10 offset-1 text-center">
          <img
            src="/img/handlettering/handlettering-unsichtbares-sichtbar-machen.svg"
            alt="Handlettering: Unsichtbares sichtbar machen"
            title="Unsichtbares sichtbar machen"
            width="370"
          />
        </div>
        <div className="col-md-5 col-12 text-center">
          <h1>Unsere Bereiche auf einen Blick</h1>
          <h3>
            Hier findest du all unsere <strong>MTRA</strong>-Bereiche im
            Überblick.
          </h3>
        </div>
      </div>
    </header>
  )
}
