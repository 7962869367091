const filterStationsSuitableForGeriatricNurses = (stations) => {
  return stations.filter(
    (station) =>
      station.metainfo &&
      station.metainfo.suitableForGeriatricNurses &&
      station.metainfo.suitableForGeriatricNurses === true
  )
}

const filterClustersOfStations = (clusters, stations) => {
  let allClusters = []
  stations.forEach((station) => {
    const stationClusters = station.metainfo.clusters
    allClusters = [...new Set([...allClusters, ...stationClusters])]
  })
  return clusters.filter((cluster) => allClusters.includes(cluster.key))
}

const filterStationsByArea = (stations, area) => {
  return stations.filter(
    (station) =>
      station.metainfo &&
      station.metainfo.areas &&
      station.metainfo.areas.includes(area)
  )
}

module.exports = {
  filterStationsSuitableForGeriatricNurses,
  filterClustersOfStations,
  filterStationsByArea,
}
