import * as React from 'react'

export function StationsHeaderCare() {
  return (
    <header className="bg-img-yellow divider-left mb-0">
      <div className="row align-items-center">
        <div className="col-md-5 offset-md-1 col-10 offset-1 text-center">
          <img
            src="/img/handlettering/handlettering-100-stationen-100-teams-100-chancen-unsere-pflegevielfalt.svg"
            alt="Handlettering: 100 Stationen, 100 Teams, 100 Chancen - Unsere Pflegevielfalt"
            title="100 Stationen, 100 Teams, 100 Chancen - Unsere Pflegevielfalt"
            width="400"
          />
        </div>
        <div className="col-md-5 col-12 text-center">
          <h1>Pflege: Unsere Stationen auf einen Blick</h1>
          <h3>
            Hier findest du all unsere Stationen im Überblick, sortiert nach
            Bereichen.
            <br />
            Du weißt, was du dir anschauen möchtest? Dann nutze einfach unsere
            Suche.
          </h3>
        </div>
      </div>
    </header>
  )
}
