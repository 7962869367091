import * as React from 'react'

export function StationsHeaderMfa() {
  return (
    <header className="bg-img-yellow divider-left mb-0">
      <div className="row align-items-center">
        <div className="col-md-5 offset-md-1 col-10 offset-1 text-center">
          <img
            src="/img/handlettering/handlettering-mit-uns-laueufts-rund.svg"
            alt="Handlettering: Mit uns läuft's rund"
            title="Mit uns läufts rund"
            width="370"
          />
        </div>
        <div className="col-md-5 col-12 text-center">
          <h1>Unsere Bereiche auf einen Blick</h1>
          <h3>
            Hier findest du all unsere <strong>MFA</strong>-Bereiche im
            Überblick, sortiert nach Fachbereichen.
            <br />
            Du weißt, was du dir anschauen möchtest? Dann nutze einfach unsere
            Suche.
          </h3>
        </div>
      </div>
    </header>
  )
}
